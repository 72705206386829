<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ warehouse.name }}</h1></template
      >
      <template v-slot:item-slot>
        <router-link class="breadcrumb-item" :to="{ name: 'Warehouse' }"
          >Warehouse</router-link
        >
        <div class="breadcrumb-item">{{ warehouse.name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <h2 class="section-title">{{ warehouse.name }}</h2>
      <p class="section-lead">
        Examples and usage guidelines for form control styles, layout options,
        and custom components for creating a wide variety of forms.
      </p>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <h4>Detail</h4>
                <router-link
                  :to="{ name: 'WarehouseEdit', params: { id: warehouse.id } }"
                  class="btn btn-warning mr-1"
                  >Edit</router-link
                >
              </div>
            </div>
            <div class="card-body">
              <h5>{{ warehouse.name }}</h5>
              <p>Venue Name : {{ warehouse.venue_name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      warehouse: [],
      token: localStorage.getItem("token"),
    };
  },
  created() {
    let id = this.$route.params.id;
    axios
      .get(this.$store.state.api + "warehouse/" + id, {
        headers: {
          Authorization: this.$store.state.token,
        },
      })
      .then((res) => {
        this.warehouse = res.data.data;
      })
      .catch((err) => console.log(err));
  },
};
</script>
